@import url("https://fonts.googleapis.com/css2?family=MuseoSans:wght@300;500;600;700&display=swap");

body {
  font-family: "Museo Sans", sans-serif !important;
  font-weight: 300;
}

* {
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

a,
span {
  font-weight: 400;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
}
.dm-section {
  background-color: #12151c;
  min-height: 100vh;
}
.main-content {
  max-width: 375px;
  text-align: center;
  margin: auto;
  padding: 0px 15px;
  position: relative;
  background-color: #12151c;
}
.dm-section .main-content .lp-absolute-content {
  width: 88%;
  margin: auto;
  padding-bottom: 20px;
}
.dm-section .main-content .lp-absolute-content .landingtext {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin: 15px 0px;
}
.dm-section .main-content .lp-absolute-content .input-wraper span {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  text-align: center;
  padding: 12px;
  height: 47px;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
}
.dm-section .main-content .lp-absolute-content .input-wraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dm-section .main-content .lp-absolute-content .input-wraper .numberinput {
  width: 80%;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: rgba(255, 255, 255, 0.1);
  padding: 12px;
  height: 47px;
  border-radius: 8px;
  color: #ffffffcc;
  font-size: 15px;
}
.dm-section
  .main-content
  .lp-absolute-content
  .input-wraper
  input::placeholder {
  color: #ffffffcc;
  font-size: 15px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.dm-section .main-content .lp-absolute-content .otpDiv {
  margin-top: 20px;
}
.dm-section .main-content .lp-absolute-content .otpDiv .otpinputWraper input {
  width: 100%;
  max-width: 20%;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: rgba(255, 255, 255, 0.1);
  height: 55px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dm-section .main-content .lp-absolute-content .otpDiv .otpinputWraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px;
}

.dm-section .main-content .lp-absolute-content .subscribe-btn button {
  padding: 15px 0px;
  width: 100%;
  text-align: center;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.2);
  color: #c7cacf;
  font-size: 14px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transform: 0.3s linear;
}
.dm-section .main-content .lp-absolute-content .subscribe-btn button:hover {
  opacity: 0.8;
}
.dm-section .main-content .lp-absolute-content .policytext {
  margin-top: 10px;
}
.dm-section .main-content .lp-absolute-content .policytext p {
  color: #a3a3ad;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  line-height: 22px;
  margin-bottom: 5px;
}
.dm-section .main-content .lp-absolute-content .policytext p a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}
.dm-section .main-content .lp-absolute-content .policytext p a:hover {
  text-decoration: underline;
}
.dm-section .main-content .lp-absolute-content .policytext p:last-child {
  margin-bottom: 0px;
}
.dm-section .main-content .lp-absolute-content .policytext .checkflex {
  display: flex;
  align-items: flex-start;
}
.dm-section .main-content .lp-absolute-content .policytext .checkflex input {
  margin-top: 6px;
}
.error {
  color: red;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
}
.invalidotp {
  color: #ffb4ab;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}
.success {
  color: #a0e5ae;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  margin-top: 10px;
}
.otpcounter a,
.otpcounter p {
  color: #c7cacf;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}
.otpcounter {
  margin-bottom: 20px;
}
.otpcounter a {
  text-decoration: none;
  color: #007aff;
}
/* light mode */
.lm-landingpage .main-content {
  background: #fff;
}
.lm-landingpage .main-content .lightlogo {
  display: block;
  margin: 0 auto;
}
.lightlogo {
  display: none;
}
.lm-landingpage .main-content .darklogo {
  display: none;
}
.lm-landingpage .main-content .lp-absolute-content .landingtext {
  color: #12151c;
}
.lm-landingpage .main-content .lp-absolute-content .input-wraper .numberinput {
  border: 1px solid rgba(18, 21, 28, 0.15);
  background: rgba(18, 21, 28, 0.05);
}
.lm-landingpage
  .main-content
  .lp-absolute-content
  .input-wraper
  input::placeholder {
  color: rgba(106, 106, 108, 0.6);
}
.lm-landingpage .main-content .lp-absolute-content .input-wraper span {
  border: 1px solid rgba(18, 21, 28, 0.15);
  background: rgba(18, 21, 28, 0.05);
  color: #6a6a6c;
}
.lm-landingpage
  .main-content
  .lp-absolute-content
  .otpDiv
  .otpinputWraper
  input {
  border: 1px solid rgba(18, 21, 28, 0.15);
  background: rgba(18, 21, 28, 0.05);
  color: #6a6a6c;
}
.lm-landingpage .main-content .lp-absolute-content .subscribe-btn button {
  background: rgba(133, 133, 134, 0.7);
  color: #fff;
}
.lm-landingpage .main-content .lp-absolute-content .policytext p {
  color: #454547;
}

.lm-landingpage .main-content .lp-absolute-content .policytext p a {
  color: #12151c;
}
.lm-landingpage .error,
.lm-landingpage .invalidotp {
  color: #ba1a1a;
}
.lm-landingpage .success {
  color: #4ba409;
}
.lm-landingpage .otpcounter p {
  color: #454547;
}
.lm-landingpage .dm-section {
  background-color: #fff;
}
/* cong box */
 .cong-box {
  background-color: #212121;
  padding: 35px 30px;
  width: 100%;
  max-width: 85%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  border-radius: 23px;
}
.cong-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
 .mubarakhead {
  color: #ffff;
  font-weight: 700;
  font-size: 22px;
  margin: 12px 0px 20px;
}
 .mubaraltext {
  color: rgba(255, 255, 255, 0.8);
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  font-weight: 300;
}
 .tamashastore {
  display: flex;
  column-gap: 15px;
  margin-top: 20px;
}
.hidden {
  display: none;
}
@media (max-width: 767px) {
  .main-content {
    padding: 0px;
  }
  .dm-section .main-content .lp-absolute-content .policytext p {
    font-size: 12px;
  }
  .cong-box {
    top: unset;
    transform: translateX(-50%);
    padding: 35px 20px;
    bottom: 30px;
  }
}
@media (max-width: 450px) {
  .main-content {
    min-width: 100%;
  }
  .main-content .lp-main-image {
    width: 100%;
  }
}
